import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IFactoryResponse, IFormular, paginate } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'timesheetDailyDetails'


// var TimesheetDailyDetailSchema = new Schema({
//   countryId: {type: String, ref: 'Country', default: null},
//   officeId: {type: Schema.ObjectId, ref: 'Office', default: null},
//   _created: {type: Date, default: Date.now},
//   display: {type: String, minlength: 3, maxlength: 40, regexp: '^.{3,40}$'},
//   targetResources:[{ type: Schema.ObjectId, ref: 'Resource'}],
//   _firmId: {type: Schema.ObjectId, ref: 'Firm', index: true},
//   authorId: {type: Schema.ObjectId, ref: 'Resource'},
//   modifiedBy: [{type: Schema.ObjectId, ref: 'Resource'}],
//   isDeleted: {type: Boolean, default: false},
//   type:String,
//   dailyFunction:String
// });


type TTimesheetDailyDetailsResponse = {
  _id: string
  display: string
  type: string
  dailyFunction: string
}

const getTimesheetAvailableDailyDetails = (axios: IFactoryFunctionRequest<TTimesheetDailyDetailsResponse[],TTimesheetDailyDetailsResponse[]>) => ({}: { }): IFactoryResponse<TTimesheetDailyDetailsResponse[],TTimesheetDailyDetailsResponse[]> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/fillTimesheet`,
  })
}

type TPaginatedTimesheetAvailableDailyDetailsResponse = {
  _id: string
  display: string
  type: string
}

const getPaginated = (axios: IFactoryFunctionRequest<any, any>) => ({ options }: {options: any }): IFactoryResponse<paginate<TPaginatedTimesheetAvailableDailyDetailsResponse>, paginate<TPaginatedTimesheetAvailableDailyDetailsResponse>> => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query: options,
  })
}

export default (axios: AxiosInstance) => ({
  getTimesheetAvailableDailyDetails: getTimesheetAvailableDailyDetails(useAxios(axios)),
  getPaginated: getPaginated(useAxios(axios)),
})
