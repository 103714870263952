import { defineStore } from 'pinia'
import Tracker from '@openreplay/tracker'

// import posthog from 'posthog-js'
import { useLocalStorage } from '@vueuse/core'
import { APP_VERSION } from '@/config/constantes'
import { Modules } from '@bc-library/interface'
import { ref } from 'vue'

type Job = {
  _created: string
  positionId: string
  startDate: string
  endDate: string
  isAlumni: boolean
  isStartDate: boolean
  officeId: string
  entityId: string
  managerId: string
  managerId2: string | null
  rate: number
  startDateLOA: string | null
  endDateLOA: string | null
  _id: string
}

type Degree = {
  _created: string
  degree: string
  endYear: number
  description: string
  isMain: boolean
  _id: string
}

type Nationality = {
  countryId: string
  passportExpirationDate: string | null
  passportIssueDate: string | null
  _id: string
}

type Display = {
  FR: string
  EN: string
}

type RadioArray = {
  _id: number
  display: Display
}

type SubAccess = {
  _id: number
  isActive: boolean
  isRadio: boolean
  radioArray: RadioArray[]
  radioValue?: number
  checkboxSource?: string
  checkboxArray?: any[]
  display: Display
  isAccess: boolean
}

type Module = {
  _id: number
  isActive: boolean
  moduleId: number
  isAccess: boolean
  subAccess: SubAccess[]
}

type AdminModule = Module
type ResourceModule = Module


type UserProfile = {
  _id: string
  isDeleted: boolean
  language: string
  isAdminProfile: boolean
  isSubAdminProfile: boolean
  isResourceProfile: boolean
  isCandidateProfile: boolean
  adminModules: AdminModule[]
  resourceModules: ResourceModule[]
  _firmId: string
  authorId: string
  display: string
  _created: string
  __v: number
  modules: Modules
}

type CurrentJob = {
  _created: string
  positionId: string
  startDate: string
  isAlumni: boolean
  isStartDate: boolean
  officeId: string
  entityId: string
  managerId: string
  managerId2: string | null
  rate: number
  startDateLOA: string | null
  endDateLOA: string | null
  _id: string
  position: {
    _id: string
    isDeleted: boolean
    authorId: string
    _firmId: string
    modifiedBy: string[]
    order: number | null
    display: string
    acronym: string
    isTemp: boolean
    isExecutive: boolean
    visibleStaffing: boolean
    visibleJobing: boolean
    visibleTimesheet: boolean
    isBudgetable: boolean
    categoryRating: number | null
    skillRating: number | null
    upperJobs: string[]
    rates: number[]
    _created: string
    skills: string[]
    __v: number
  }
  office: {
    _id: string
    isDeleted: boolean
    countryId: string
    timeZone: number
    display: string
    _firmId: string
    __v: number
  }
}

type Anonymize = {
  anonymizeDate: string | null
  anonymizeByR: string | null
  anonymizeByU: string | null
}

type ApplicationSettings = Record<string, unknown>

type User = {
  _id: string
  shortcuts: string[]
  isOnboarded: boolean
  isOnboardedAdmin: boolean
  role: string
  picture: string
  countryId: string
  officeId: string
  isDeleted: boolean
  candidateSource: number
  invitedBy: string
  recommendedBy: string | null
  assistants: string[]
  modifiedBy: string[]
  resourceType: number
  isActive: number
  dataDuration: number
  departureDate: string | null
  isDepartureWanted: boolean
  birthdate: string
  birthPlace: string | null
  maritalStatus: string | null
  socialSecurity: string | null
  addressLine1: string
  addressLine2: string
  addressZipcode: string
  addressCity: string
  addressCountry: string
  phoneNumber1: string
  phoneNumber2: string | null
  mailPersonal: string
  wishList: string[]
  userGroups: string[]
  index: string
  isActivated: boolean
  CGUvalidatedDate: string
  mailMod: number[]
  jobs: Job[]
  firstname: string
  lastname: string
  accessProfile: string
  _firmId: string
  firm: {
    _id: string
    company: string
    isBL: boolean
    isWL: boolean
    squareLogo: string
    picture: string
  }
  _created: string
  recruitmentDate: string
  experiences: string[]
  projects: string[]
  skills: string[]
  degrees: Degree[]
  documents: string[]
  comments: string[]
  userId: string
  __v: number
  gender: number
  username: string
  nationality: string
  givenLastname: string
  maidenname: string
  nationalities: Nationality[]
  zoneId: string[]
  anonymize: Anonymize
  applicationSettings: ApplicationSettings
  availableProfileIds: string[]
  companyZoneId: string[]
  currentJob: CurrentJob
  profile: UserProfile
}

type RootObject = {
  authRefreshTryed: boolean
  user: User
  token: string
}

export const useUserStore = defineStore('user', () => {

  const authRefreshTryed = ref(false)
  const user = ref<User>()
  const token = ref<string>()

  function connection({ user: newUser, token: newToken }) {

    user.value = newUser
    token.value = newToken
    authRefreshTryed.value = false
    if (!window?.location.host.includes('127.0.0.1') && !window?.location.host.includes('localhost')) {
      if (user?.userId) {
        const tracker = new Tracker({
          projectKey: 'miGUGdJfXWzTEogA7AFc',
          ingestPoint: 'https://openreplay.bc.direct/ingest',
        })
        tracker.setUserID(newUser.user.email)
        tracker.setMetadata('resourceId', newUser._id)
        tracker.setMetadata('name', `${newUser.lastname} ${newUser.firstname}`)
        tracker.setMetadata('profile', newUser.profile.display)
        tracker.setMetadata('firm', newUser.firm.company)
        tracker.setMetadata('_firmId', newUser._firmId)
        tracker.setMetadata('picture', newUser.picture)
        tracker.setMetadata('release', APP_VERSION)
      }
    }

    return { user }
  }

  const disconnection = () => {
    authRefreshTryed.value = false
    user.value = undefined
    token.value = undefined
  }


  return {
    authRefreshTryed,
    user,
    token,
    connection,
    disconnection,
  }
},
  {
    persist: true,
  }
)
