<script setup lang="ts">
import { ref } from 'vue'
import bButton from './bButton.vue'
import IconArrowDown from '~icons/ic/sharp-keyboard-arrow-down'
import { bgColors } from '@/services/application/constantes'

const props = withDefaults(defineProps<{
  items: item[]
  position?: 'auto' |
  'auto-start' |
  'auto-end' |
  'top' |
  'top-start' |
  'top-end' |
  'right' |
  'right-start' |
  'right-end' |
  'bottom' |
  'bottom-start' |
  'bottom-end' |
  'left' |
  'left-start' |
  'left-end'
  disabled?: boolean
}>(), {
  'items.bgColor': 'base',
  'position': 'auto',
  'disabled': false,
})

const emit = defineEmits<{
  // (event: 'update:isOpen', value: boolean): void
}>()

const isOpen = defineModel<boolean>('isOpen', { default: false })

const target = ref(null)

type item = {
  display: string
  to?: string
  click: () => void
  bgColor: keyof typeof bgColors
  _id?: string
}

const closePopup = () => {

}
const clickItem = (item: item, hide) => {
  if (item.click)
    item.click()
  hide()
  isOpen.value = false
}
</script>

<template>
  <VDropdown
    v-model:shown="isOpen" :distance="6" :placement="position"
    :disabled="disabled" @click.stop
  >
    <slot name="triggerContent">
      <span>
        <IconArrowDown class="cursor-pointer" />
      </span>
    </slot>
    <template #popper="{ hide }">
      <slot name="listContent">
        <ul class="shadow bg-base max-h-56 overflow-x-hidden overflow-y-auto rounded-xl border border-base color-base text-base transition-all">
          <slot v-for="item in props.items" :name="`item-named-${item._id}`" :item="item" :hide="hide">
            <slot name="item" :item="item" :hide="hide">
              <li :key="item.display" :class="[bgColors[item.bgColor], { 'hover:bg-fade': item.bgColor === 'base' }]" class="item px-2 py-2 text-sm cursor-pointer select-none border-0" @click="clickItem(item, hide)">
                <slot name="itemDisplay" :item="item">
                  <slot :name="`item-display-${item._id}`">
                    <template v-if="item.to">
                      <router-link :to="item.to">
                        {{ item.display }}
                      </router-link>
                    </template>
                    <template v-else>
                      {{ item.display }}
                    </template>
                  </slot>
                </slot>
              </li>
            </slot>
          </slot>
        </ul>
      </slot>
    </template>
  </VDropdown>
</template>
