import type { AxiosInstance } from 'axios'
import type { IFactoryFunctionRequest, IPaginated, IResource, ISkill } from '@bc-library/interface'
import { EMethod, IFactoryResponse, IOffice } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'invoices'

type TShowInvoice = {
  _id: string
  startDate: string
  endDate: string
  display: string
  formAfter: {
    _id: string
    column: [{
      _id: string
      type: TItems
      display: string | null
      displayArray: any[]
      value: any
      buttons: number
      calcId: string
      averageIds: string[]
      bgColor: string
      color: string
      required: boolean
      showNA: boolean
      hideable: boolean
      hiddenBy: string
      rows: number
    }]
  }[]
  form: {
    _id: string
    column: [{
      _id: string
      type: TItems
      display: string | null
      displayArray: any[]
      value: any
      buttons: number
      calcId: string
      averageIds: string[]
      bgColor: string
      color: string
      required: boolean
      showNA: boolean
      hideable: boolean
      hiddenBy: string
      rows: number
    }]
  }[]
}

type TstaffingSetup = {
  list: [
    {
      streamId: IResource
      days: number
      skillList: [ISkill]
      staffingPeriod: [{
        days: number
        startDate: { type: Date }
        endDate: { type: Date }
      }]
      candidateList: [IResource]
      resourceList: [{
        resourceId: IResource
        status: number
      }]
      dailyCost: number
      dailyPrice: number
      productionPrice: number
      listPrice: number
      billedDays: number
      realDays: number
      forecast: [{
        forecastDate: Date
        realDays: number
      }]
    },
  ]
}

type TShowInvoiceEngagement = {
  _id: string
  _firmId: string
  startDate: string
  endDate: string
  display: string
  directorId: string
  projectTarget: string
  staffingSetup: TstaffingSetup
}

const showInvoicesList = (axios: IFactoryFunctionRequest<IPaginated<TShowInvoice>, undefined>) => (query: { limit: number; page: number }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/`,
    query,
  })
}
const clientInvoiceList = (axios: IFactoryFunctionRequest<IPaginated<TShowInvoice>, undefined>) => (query: { limit: number; page: number }) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/client`,
    query,
  })
}

export default (axios: AxiosInstance) => ({
  showInvoicesList: showInvoicesList(useAxios(axios)),
  clientInvoiceList: clientInvoiceList(useAxios(axios)),
})
