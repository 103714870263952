export * from './moduleAccess';

export type paginate<T> = {
  docs: T[]
  count: {
    currentPage: number
    totalPages: number
    nextPage: number
    hasNextPage: boolean
    limit: number
    total: number
  }
}

export type IModule = {
  _id: number
  catalogPageOrder: number
  homePageOrder: number
  portalPageOrder: number
  order: number
  range: number
  moduleType: number
  portalType: number
  icon: string
  display: { FR: string; EN: string }
  managerDisplay: { FR: string; EN: string }
  shortDisplay: { FR: string; EN: string }
  portalDisplay: { FR: string; EN: string }
  description: { FR: string; EN: string }
  imageFile: string
  price: string
  resourceType: string
  route: string
  portalRoute: string
  managerRoute: string
  statisticRoute: string
  isManager: boolean
  isProduction: boolean
  isBeta: boolean
  isAlpha: boolean
  isPortalModule: boolean
  isPortalXSModule: boolean
}

export type IRoom = {
  isDeleted: boolean
  display: string
  email: string
  countryId: Array<ICountry | string>
  officeId: null | IOffice | string
  _firmId: IFirm | string
}

export type IModuleSubscribeModules = {
  _id: number
  resourceType: string | null
  range: number | null
}

export type ISubscription = {
  _id: string
  _created: Date | string
  moduleId: number | IModuleSubscribeModules
  dateStart?: Date | string
  isTrial: boolean | null
  subscribingUser: string
  accessibleBy: Array<IResource>
  dateTermination?: Date | null | string
  CGVvalidatedDate?: Array<Date | null | string>
  subscriptionTerminatedOn?: Date | string | null
  subscriptionTerminatedBy?: IResource
  ranges: number
}

type ICurrency = {
  _id: string
  display: string
  symbol: string
  symbol_native: string
  name_plural: string
}

export type IFirm = {
  _id: string
  _created: Date | string
  company: string
  picture: string
  code: string
  isBl: boolean
  isWL: boolean
  homePage: string
  subDomain: string
  outboundMail: string | null
  thumbPicture: string
  squareLogo: string
  address1: string
  address2?: string | null
  zipcode: string
  city: string
  country: string
  taxnumber: string
  expenseKey: string
  expenseId: string
  expenseProvider: string
  eSignature: boolean
  tsDay: number
  tsMax: boolean
  corporateMail: string
  subscribedModules: Array<ISubscription>
  newCGVRead: boolean
  billingDay?: number
  billingMail?: string | null
  // coupon: { type: Schema.ObjectId, ref: 'Coupon' },
  accountStartedOn?: Date | string
  jobBoardBanner: string
  jobBoardTitle: { FR: string; EN: string }
  jobBoardContent: { FR: string; EN: string }
  jobBoardVideo: string
  jobBoardLinkedIn: string
  jobBoardTwitter: string
  jobBoardFacebook: string
  jobBoardInstagram: string
  mainCurrency: string | ICurrency
  fiscalMonthStart: number
  CGVvalidatedLog: Array<{
    validatedBy: string | IResource | null
    dateSaved: Date | string
  }>
  userThreshold: Array<{
    headcount: number
    dateSaved: Date | string
  }>
}

export type ILeaveFirm = {
  _id: string
  _created: Date | string
  company: string
  picture: string
  code: string
  isBl?: boolean
  isWL: boolean
  homePage: string
  subDomain: string
  outboundMail: string | null
  thumbPicture: string
  address2?: string | null
  eSignature: boolean
  corporateMail: string
  subscribedModules: Array<ISubscription>
  newCGVRead: boolean
  billingDay?: number
  billingMail?: string | null
  accountStartedOn?: Date | string
  mainCurrency: string | ICurrency
  fiscalMonthStart: number
  CGVvalidatedLog: Array<{
    validatedBy: string | IResource | null
    dateSaved: Date | string
  }>
  userThreshold: Array<{
    headcount: number
    dateSaved: Date | string
  }>
}

export type IShortcut = {
  _id: number
  moduleId: number
  route: string
  isHR: boolean
  display: { FR: string; EN: string }
}

export type ITimezone = {
  _id: number
  display: string
  gmtAdjustment: string
  useDaylightTime: boolean
  value: number
}

export type IOffice = {
  _id: string
  isDeleted: boolean
  display: string
  countryId: string | ICountry | null
  timeZone: number | ITimezone
  attachMR: boolean
  evaluationForm?: string | IFormular
  _firmId: string | IFirm
  region?: string
}

export type IDepartureCategory = {
  _id: string
  isDeleted: boolean
  _created: Date | string
  display: string
  _firmId: string | IFirm
  authorId?: string | IResource
  modifiedBy: Array<IResource>
}

export type IInvoicingInformation = {
  _id: string
  _created: Date | string
  isDeleted: boolean
  display: string
  defaultVAT: number
  fiscalMonthStart: number
  invoicenumberStart: number
  header: string
  countryId: string | ICountry | null
  currency: string | ICurrency
  content: string
  footer: string
  _firmId: string | IFirm
  authorId: string | IResource
  modifiedBy: Array<string | IResource>
  corporateMail: string
}

export type ISkillCartegory = {
  _id: string
  _created: Date | string
  isDeleted: boolean
  display: string
  order: number
  image: {
    filePath?: string
    fileName?: string
    fileType?: string
  }
  _firmId: string | IFirm
  authorId: string | IResource
  modifiedBy: Array<IResource>
  ratingButtons :  Array<ISkillRatingButtons>
}

export type ISkillRatingButtons = {
  value: number,
  display: string
}
export type ISkill = {
  _id: string
  _created: Date | string
  isDeleted: boolean
  display: string
  order: number
  image: {
    filePath?: string | null
    fileName?: string | null
    fileType?: string | null
  }
  authorId: string | IResource
  modifiedBy: Array<IResource>
  categoryId: string | ISkillCartegory
  category?: string | ISkillCartegory
  _firmId: string | IFirm
}

export type IJob = {
  _id: string
  _created: Date | string
  isDeleted: boolean
  authorId: string | IResource
  _firmId: string | IFirm
  modifiedBy: Array<IResource>
  order?: number | null
  display: string
  acronym: string
  isTemp: boolean
  isExecutive: boolean
  visibleStaffing: boolean
  visibleJobing: boolean
  visibleTimesheet: boolean
  isBudgetable: boolean
  skills: string | Array<ISkill>
  categoryRating?: any
  skillRating?: any
  upperJobs: Array<IJob>
  rates: Array<IRates>
}

export type IJobsLeave = Pick<IJob, '_id' | 'display' | 'acronym'>

export type IRates = {
  countryId: string | ICountry
  defaultCost: number
  defaultPrice: number
  standardWage: number
}

export type IJobStatus = {
  _id: string
  _created: Date | string
  isDeleted: boolean
  display: string
  _firmId: string | IFirm
  countryId: string
}

export type IContractType = {
  _id: string
  isDeleted: boolean
  display: string
  country?: ICountry | null
  countryId?: string | ICountry | null
  _firmId: string | IFirm
}

export type ISector = {
  _id: string
  isDeleted: boolean
  _created: Date | string
  display: string
  _firmId: string | IFirm
  authorId: string | IResource
  modifiedBy: Array<IResource>
}

export type IBankAccount = {
  _id: string
  _created: Date | string
  isDeleted: boolean
  countryId?: string | ICountry
  display: string
  content: string
  _firmId: string | IFirm
}

export type ICompany = {
  _id: string
  _created: Date | string
  isDeleted: boolean
  display: string
  sectorId: string | ISector
  authorId: string | IResource
  modifiedBy: Array<string | IResource>
  parentId?: string | ICompany
  isClient: boolean
  isSupplier: boolean
  owners: string | IResource
  owners2: string | IResource
  notes: [
    {
      _created: Date | string
      headline: string
      content: string
      authorId: string | IResource
    },
  ]
  documents: [
    {
      _created: Date | string
      authorId: string | IResource
      filePath: string
      fileName: string
      fileType: string
      fileSize: number
      fileDescription: string
      isLocked: boolean
      isPublic: boolean
    },
  ]
  phonenumber1: string
  address1: string
  address2: string
  zipcode: string
  city: string
  country: string
  taxnumber: string
  defaultVAT: number
  billingMail: string
  bankAccount: string | IBankAccount
  invoicingEntities: [
    {
      invoicingFirm: string | IFirm
      display: string
      phonenumber1: string
      address1: string
      address2: string
      zipcode: string
      city: string
      country: string
      taxnumber: string
      defaultVAT: number
      billingMail: string
      bankAccount: string | IBankAccount
    },
  ]
  _firmId: string | IFirm
}

export type ICompanyContact = {
  _id: string
  _created: Date | string
  isDeleted: boolean
  authorId: string | IResource
  firstname: string
  lastname: string
  phonenumber1: string
  phonenumber2: string
  email: string
  linkedin: string
  title: string
  division: string
  assistant: string | ICompanyContact
  notes: string
  comments: [
    {
      _created: Date | string
      comment: string
      authorId: string | IResource
    },
  ]
  isActive: boolean
  picture: string
  companyId: string | ICompany
  _firmId: string | IFirm
}

export type IProjectcategory = {
  _id: string
  _created: Date | string
  isDeleted: boolean
  display: string
  _firmId: string | IFirm
  authorId: string | IResource
  modifiedBy: Array<IResource>
}

export type IUserGroup = {
  _id: string
  isDeleted: boolean
  display: string
  authorId: string | IResource
  modifiedBy: Array<string | IResource>
  _firmId: string | IFirm
}

export type IInvoicingTerm = {
  _id: string
  _created: Date | string
  isDeleted: boolean
  // 0 : Emision date, 1: Reception date, 2: End of month
  paymentStartDate: string
  addedDays: number
  display: string
  _firmId: string | IFirm
}

export type IInvoicingProject = {
  _id: string
  // 0:Invoice, 1:Credit
  invoiceType: number
  countryId?: string | ICountry
  _created: Date | string
  isLocked: boolean
  _firmId: string | IFirm
  authorId: string | IResource
  numberPrefix: string
  invoicenumber?: number
  invoicenumberReference: string
  projectId: string | IProject
  invoicingInformation: string | IInvoicingInformation
  currency: string | ICurrency
  client: string
  supplier: string
  title: string
  toBeInvoicedDate: Date
  toBeCollectedDate: Date
  invoicedDate: Date
  sentDate: Date
  paidDate: Date
  cancelledDate: Date
  blockedDate: Date
  rejectedDate: Date
  lines: [
    {
      _created: Date | string
      activity: string
      amount: number
      vat: number
    },
  ]
  rebate: number
  // 0:not created / 1:created / 2:sent / 3:blocked / 4:paid / 5:cancelled / 6:rejected
  status: number
  startDate: Date | string
  endDate: Date | string
  content: string
  footer: string
  bankAccount: string
  invoicingEntity: string
  clientnumber: string
  contractnumber: string
  invoicingTerm: string | IInvoicingTerm
  logs: [
    {
      _id: string
      _created: Date | string
      actionId: number
      authorId: string | IResource
      date: Date
      amount: number
      vat: number
      comment: string
      sentTo: string
      document: {
        filePath?: string
        fileName?: string
        fileType?: string
      }
    },
  ]
  invoiceFile: {
    filePath?: string
    fileName?: string
    fileType?: string
  }
}

export type IStream = {
  _id: string
  display: string
  startDate: Date | string
  endDate: Date | string
  managerId: string | IResource
}

export type TStaffingGanttline =
  | 'project'
  | 'title'
  | 'position'
  | 'stream'
  | 'Staffed'
  | 'To be staffed'

export type staffingGanttResourcePositions = {
  type: TStaffingGanttline
  staffingPeriod: Array<{ startDate: Date | string; endDate: Date | string }>
  streams?: Array<IStream>
  streamIndex?: number
  streamId?: string
  display?: string
  resource?: IResource
  index?: number
  status?: string
  order?: number
  positionId?: IJob
}

export type IProject = {
  _id: string
  countryId?: string | ICountry
  officeId?: string | IOffice
  _created: Date | string
  isDeleted: boolean
  isArchived: boolean
  isLocked: boolean
  // 2 targets : client / internal
  projectTarget: string
  authorId: string | IResource
  display: string
  _firmId: string | IFirm
  isPublic: boolean
  projectCode: string
  clientId: string | ICompany
  clientContactId: Array<ICompanyContact>
  startDate: Date | string
  endDate: Date | string
  soldDate: Date
  mainCurrencyMultiplier: number
  projectCity: string
  projectCountry: string
  projectStatus: number
  projectCategory: string | IProjectcategory
  directorId: string | IResource
  managerId: string | IResource
  tsWeekend: boolean
  isActive: boolean
  description: string
  skillTags: Array<ISkill>
  groupTags: Array<IUserGroup>
  staffingStatus: number
  streams: Array<IStream>
  staffingSetup: [
    {
      _id: string
      positionId: string | IJob
      list: [
        {
          _id: string
          streamId: string
          days: number
          skillList: Array<ISkill>
          staffingPeriod: [
            {
              days: number
              startDate: Date | string
              endDate: Date | string
            },
          ]
          candidateList: Array<IResource>
          resourceList: [
            {
              resourceId: string | IResource
              status: number
            },
          ]
          dailyCost: number
          dailyPrice: number
          productionPrice: number
          listPrice: number
          billedDays: number
          realDays: number
          forecast: [
            {
              forecastDate: Date
              realDays: number
            },
          ]
        },
      ]
    },
  ]

  conditionalFees: [
    {
      display: string
      cost: number
      price: number
      invoicingDate: Date
      isAchieved: boolean
    },
  ]

  subcontractor: [
    {
      companyId: string
      streamId: string
      dailyCost: number
      dailyPrice: number
      listPrice: number
      billedDays: number
      realDays: number
      forecast: [
        {
          forecastDate: Date
          realDays: number
        },
      ]
    },
  ]

  monitorExpenses: boolean
  monitorSubcontractors: boolean
  forecasts: [
    {
      forecastDate: Date
    },
  ]
  rebate: number
  leadPrice: number
  leadExpenses: number
  leadAdminExpenses: number
  streamExpenses: [
    {
      streamId: string
      amount: number
    },
  ]
  streamAdminExpenses: [
    {
      streamId: string
      amount: number
    },
  ]
  parentId: string
  leadStatus: [
    {
      date: Date
      status: number
    },
  ]
  currentStatus: number // O : sold, -1: abandonned, -2: lost
  leadProbability: number
  contributors: [
    {
      contributorId: string | IResource
      percentage: number
    },
  ]
  invoices: Array<IInvoicingProject>
  invoicingInformation: string | IInvoicingInformation
  invoicingEntity: string
  bankAccount: string
  clientnumber: string
  contractnumber: string
  invoicingTerm: string | IInvoicingTerm
  notes: [
    {
      _created: Date | string
      headline: string
      content: string
      authorId: string | IResource
    },
  ]
  documents: [
    {
      _created: Date | string
      authorId: string | IResource
      filePath: string
      fileName: string
      fileType: string
      fileSize: number
      fileDescription: string
      isLocked: boolean
      isPublic: boolean
    },
  ]
}

export type ILeaveValidatorTargetResources = Pick<
  IResource,
  'picture' | '_id' | 'firstname' | 'lastname'
>

export type ILeaveUserJobs = Pick<
  IResource,
  'picture' | '_id' | 'firstname' | 'lastname' | 'jobs'
>

export type ILeaveResourceId = Pick<
  IResource,
  'picture' | '_id' | 'firstname' | 'lastname' | 'username'
>

export type IResourceId = Pick<
  IResourceCurrentJob,
  'picture' | '_id' | 'firstname' | 'lastname' | 'userId' | 'jobs'
>

export type IResourceInvitedBy = Pick<
  IResourceRecruitmentDate,
  'picture' | '_id' | 'firstname' | 'lastname' | 'username' | 'userId'
>

export type IAcquiredLeaveTypeJobs = Pick<
  IResourceRecruitmentDate,
  'picture' | '_id' | 'firstname' | 'lastname' | 'jobs'
>

export type IUserResourceId = Pick<
  IResourceRecruitmentDate,
  'picture' | '_id' | 'firstname' | 'lastname' | 'username'
>
export type IResource = {
  _id: string
  _created: Date | string
  lastConnection?: Date | string
  shortcuts: Array<number | IShortcut>
  isOnboarded: boolean
  isOnboardedAdmin: boolean
  userId: string | IUser
  user: IUser
  role: string
  picture: string | null
  countryId?: string | ICountry | null
  officeId?: IOffice | string | null
  office?: IOffice
  currentJob?: ICurrentJob
  zoneId: Array<string | IOffice | null>
  isDeleted: boolean
  _firmId: string | IFirm
  firm?: IFirm
  candidateSource: number
  invitedBy?: string | IInvitedBy | IResource | null
  recommendedBy?: string | IResource | null
  assistants: Array<string | IResource>
  mainAssistant: string
  modifiedBy: Array<string | IResource>
  // 0: Interne, 1: Externe
  resourceType: number
  // 0: Oui, 1: Alumni, 2: Leave
  isActive: number
  username?: string
  firstname: string
  lastname: string
  givenFirstname: string
  givenLastname: string
  maidenname: string
  dataDuration: number
  recruitmentDate: Date | string
  probationEnd1?: Date | string | null
  probationEnd2?: Date | string | null
  departureDate?: Date | string | null
  departureDatePhysical?: Date | string | null
  isDepartureWanted: boolean
  departureCategoryId?: string | IDepartureCategory | null
  birthdate?: Date | string | null
  birthPlace: string | null
  maritalStatus: string | null
  socialSecurity: string | null
  invoicingInformation?: string | IInvoicingInformation
  nationality?: string | ICountry | null
  nationalities: Array<{
    _id: string
    countryId: string | ICountry
    passportNumber?: string
    passportExpirationDate?: Date | string | null
    passportIssueDate?: Date | string | null
  }>
  // 0: Homme, 1: Femme
  gender: number
  addressLine1?: string | null
  addressLine2?: string | null
  addressZipcode?: string | null
  addressCity?: string | null
  addressCountry?: string | null
  phoneNumber1?: string | null
  phoneNumber2?: string | null
  phoneNumberPersonal?: string | null
  mailPersonal?: string | null
  linkedin?: string
  description?: string
  jobs: Array<ICurrentJob>
  experiences: Array<{
    _id: string
    _created: Date | string
    companyName: string
    sectorId?: string | ISector
    sector?: ISector
    jobTitle: string
    contractType?: string | IContractType
    startYear: number
    startMonth?: number
    endYear: number
    endMonth?: number
    description?: string
    _firmId?: string | IFirm
  }>
  projects: Array<{
    _created: Date | string
    projectId: IProject
    companyName: string
    sectorId?: string | ISector
    projectCategoryId?: string | IProjectcategory
    duration: number
    startYear: number
    description?: string
  }>
  skills: Array<{
    _id: string
    _created: Date | string
    skillId: string | ISkill
    skill: ISkill
    isMajor: boolean
    taggedBy: Array<{
      resourceId: string | IResource
      _created: Date | string
    }>
    value: number,
  }>
  wishList: Array<string | ISkill>
  degrees: Array<{
    _id: string
    _created: Date | string
    degree?: string
    schoolId?: string | ISchool
    categoryId?: string | IDegreeCategory
    startYear?: number
    endYear?: number
    description?: string
    isMain: boolean
  }>
  userGroups: Array<IUserGroup>
  accessProfile: string | IProfile
  availableProfileIds : Array<string | IProfile>
  documents: Array<{
    _created: Date | string
    authorId: string | IResource
    filePath: string
    fileName: string
    fileType: string
    fileSize: number
    fileDescription: string
    isLocked: boolean
    isPublic: boolean
  }>
  comments: Array<{
    _created: Date | string
    headline: string
    message: string
    authorId: string | IResource
  }>
  index?: string
  isActivated: boolean
  CGUvalidatedDate?: Date | string
  // Automatice e-mail notification setting (array contains module Id)
  mailMod: Array<number>
}

export type IResourceInvitedByNull = {
  _id: string
  role: string
  picture: string | null
  firstname: string | null
  lastname: string | null
  userId: string | IUser | null
}
export type IResourceRecruitmentDate = {
  _id: string
  countryId?: string | ICountry
  _created: Date | string
  lastConnection?: Date | string
  shortcuts: Array<IShortcut>
  isOnboarded: boolean
  isOnboardedAdmin: boolean
  userId: string | IUser
  role: string
  picture: string | null
  officeId?: IOffice | string
  zoneId?: Array<IOffice>
  isDeleted: boolean
  _firmId: string | ILeaveFirm
  candidateSource: number
  invitedBy?: string | IInvitedBy
  recommendedBy?: IResource | null
  assistants: Array<IResource>
  modifiedBy: Array<string | IResource>
  // 0: Interne, 1: Externe
  resourceType: number
  // 0: Oui, 1: Alumni, 2: Leave
  isActive: number
  username?: string
  firstname: string
  lastname: string
  givenFirstname?: string
  givenLastname?: string
  maidenname?: string
  dataDuration: number
  recruitmentDate: Date | string
  probationEnd1?: Date | string | null
  probationEnd2?: Date | string | null
  departureDate?: Date | string | null
  departureDatePhysical?: Date | string | null
  isDepartureWanted: boolean
  departureCategoryId?: string | IDepartureCategory | null
  birthdate?: Date | string | null
  birthPlace: string | null
  maritalStatus: string | null
  socialSecurity: string | null
  invoicingInformation?: string | IInvoicingInformation
  nationality?: string | ICountry | null
  nationalities: Array<{
    countryId: string | ICountry
    passportnumber?: string
    passportExpirationDate?: Date | string | null
    passportIssueDate?: Date | string | null
  }>
  // 0: Homme, 1: Femme
  gender: number
  addressLine1?: string | null
  addressLine2?: string | null
  addressZipcode?: string | null
  addressCity?: string | null
  addressCountry?: string | null
  phoneNumber1?: string
  phoneNumber2?: string | null
  phonenumberPersonal?: string
  mailPersonal?: string
  linkedin?: string
  description?: string
  jobs: Array<ICurrentJob>
  experiences: Array<{
    _created: Date | string
    companyName: string
    sectorId: string | ISector | null
    jobTitle: string
    contractType?: string | IContractType
    startYear: number
    startMonth?: number
    endYear: number
    endMonth?: number
    description?: string | null
    _firmId?: string | IFirm
  }>
  projects: Array<{
    _created: Date | string
    projectId: IProject
    companyName: string
    sectorId?: string | ISector
    projectCategoryId?: string | IProjectcategory
    duration: number
    startYear: number
    description?: string
  }>
  skills: Array<{
    _created: Date | string
    skillId: string | ISkill
    isMajor: boolean
    taggedBy: Array<{
      resourceId: string | IResource
      _created: Date | string
    }>
  }>
  wishList: Array<string | ISkill>
  degrees: Array<{
    _id: string
    _created: Date | string
    degree?: string
    schoolId?: string | ISchool | null
    categoryId?: string | IDegreeCategory | null
    startYear?: number | null
    endYear?: number | null
    description: string | null
    isMain: boolean
  }>
  userGroups: Array<IUserGroup>
  accessProfile: string | IProfile
  documents: Array<{
    _created: Date | string
    authorId: string | IResource
    filePath: string
    fileName: string
    fileType: string
    fileSize: number
    fileDescription: string
    isLocked: boolean
    isPublic: boolean
  }>
  comments: Array<{
    _created: Date | string
    headline: string
    message: string
    authorId: string | IResource
  }>
  index?: string
  isActivated: boolean
  CGUvalidatedDate?: Date | string
  // Automatice e-mail notification setting (array contains module Id)
  mailMod: Array<number>
}

export type IResourceEnAttente = {
  _id: string
  countryId?: string | ICountry
  _created: Date | string
  lastConnection?: Date | string
  shortcuts: Array<IShortcut>
  isOnboarded: boolean
  isOnboardedAdmin: boolean
  userId: any
  role: string
  picture: string | null
  officeId?: IOffice | string
  zoneId?: Array<IOffice>
  isDeleted: boolean
  _firmId: string | ILeaveFirm
  candidateSource: number
  invitedBy?: string | IResourceInvitedBy
  recommendedBy?: IResource | null
  assistants: Array<IResource>
  modifiedBy: Array<string | IResource>
  // 0: Interne, 1: Externe
  resourceType: number
  // 0: Oui, 1: Alumni, 2: Leave
  isActive: number
  username?: string
  firstname: string
  lastname: string
  givenFirstname?: string
  givenLastname?: string
  maidenname?: string
  dataDuration: number
  recruitmentDate: Date | string
  probationEnd1?: Date | string
  probationEnd2?: Date | string | null
  departureDate?: Date | string | null
  departureDatePhysical?: Date | string | null
  isDepartureWanted: boolean
  departureCategoryId?: string | IDepartureCategory
  birthdate?: Date | string | null
  birthPlace: string | null
  maritalStatus: string | null
  socialSecurity: string | null
  invoicingInformation?: string | IInvoicingInformation
  nationality?: string | ICountry | null
  nationalities: Array<{
    countryId: string | ICountry
    passportnumber?: string
    passportExpirationDate?: Date | string | null
    passportIssueDate?: Date | string | null
  }>
  // 0: Homme, 1: Femme
  gender: number
  addressLine1?: string | null
  addressLine2?: string | null
  addressZipcode?: string | null
  addressCity?: string | null
  addressCountry?: string | null
  phoneNumber1?: string
  phoneNumber2?: string | null
  phonenumberPersonal?: string
  mailPersonal?: string
  linkedin?: string
  description?: string
  jobs: Array<ICurrentJob>
  experiences: Array<{
    _created: Date | string
    companyName: string
    sectorId: string | ISector | null
    jobTitle: string
    contractType?: string | IContractType
    startYear: number
    startMonth?: number
    endYear: number
    endMonth?: number
    description?: string | null
    _firmId?: string | IFirm
  }>
  projects: Array<{
    _created: Date | string
    projectId: IProject
    companyName: string
    sectorId?: string | ISector
    projectCategoryId?: string | IProjectcategory
    duration: number
    startYear: number
    description?: string
  }>
  skills: Array<{
    _created: Date | string
    skillId: string | ISkill
    isMajor: boolean
    taggedBy: Array<{
      resourceId: string | IResource
      _created: Date | string
    }>
  }>
  wishList: Array<string | ISkill>
  degrees: Array<{
    _created: Date | string
    degree: string
    schoolId: string | ISchool
    categoryId: string | IDegreeCategory
    startYear: number
    endYear: number
    description?: string
    isMain: boolean
  }>
  userGroups: Array<IUserGroup>
  accessProfile: string | IProfile
  documents: Array<{
    _created: Date | string
    authorId: string | IResource
    filePath: string
    fileName: string
    fileType: string
    fileSize: number
    fileDescription: string
    isLocked: boolean
    isPublic: boolean
  }>
  comments: Array<{
    _created: Date | string
    headline: string
    message: string
    authorId: string | IResource
  }>
  index?: string
  isActivated: boolean
  CGUvalidatedDate?: Date | string
  // Automatice e-mail notification setting (array contains module Id)
  mailMod: Array<number>
}
export type IResourceCurrentJob = {
  _id: string
  countryId?: string | ICountry
  _created: Date | string
  lastConnection?: Date | string
  shortcuts: Array<IShortcut>
  isOnboarded: boolean
  isOnboardedAdmin: boolean
  userId: string | IUser
  role: string
  picture: string
  officeId?: IOffice | string
  zoneId?: Array<IOffice>
  isDeleted: boolean
  _firmId: string | ILeaveFirm
  candidateSource: number
  invitedBy?: string | IResourceInvitedByNull
  recommendedBy?: IResource | null
  assistants: Array<IResource>
  modifiedBy: Array<string | IResource>
  // 0: Interne, 1: Externe
  resourceType: number
  // 0: Oui, 1: Alumni, 2: Leave
  isActive: number
  username: string
  firstname: string
  lastname: string
  givenFirstname?: string
  givenLastname?: string
  maidenname?: string
  dataDuration: number
  recruitmentDate: Date | string
  probationEnd1: Date | string
  probationEnd2: Date | string | null
  departureDate?: Date | string | null
  departureDatePhysical?: Date | string | null
  // isDepartureWanted: // boolean not used
  departureCategoryId?: string | IDepartureCategory
  birthdate?: Date | string
  birthPlace: string
  maritalStatus: string
  socialSecurity: string | null
  invoicingInformation?: string | IInvoicingInformation
  nationality: string | ICountry | null
  nationalities: Array<{
    countryId: string | ICountry
    passportnumber?: string
    passportExpirationDate?: Date | string | null
    passportIssueDate?: Date | string | null
  }>
  // 0: Homme, 1: Femme
  gender: number
  addressLine1?: string
  addressLine2?: string | null
  addressZipcode?: string
  addressCity?: string
  addressCountry?: string
  phoneNumber1?: string
  phoneNumber2?: string
  phonenumberPersonal?: string
  mailPersonal?: string
  linkedin?: string
  description?: string
  jobs: Array<ICurrentJob>
  experiences: Array<{
    _created: Date | string
    companyName: string
    sectorId: string | ISector | null
    jobTitle: string
    contractType?: string | IContractType
    startYear: number
    startMonth?: number
    endYear: number
    endMonth?: number
    description?: string | null
    _firmId?: string | IFirm
  }>
  projects: Array<{
    _created: Date | string
    projectId: IProject
    companyName: string
    sectorId?: string | ISector
    projectCategoryId?: string | IProjectcategory
    duration: number
    startYear: number
    description?: string
  }>
  skills: Array<{
    _created: Date | string
    skillId: string | ISkill
    isMajor: boolean
    taggedBy: Array<{
      resourceId: string | IResource
      _created: Date | string
    }>
  }>
  wishList: Array<string | ISkill>
  degrees: Array<{
    _id: string
    _created: Date | string
    degree?: string
    schoolId?: string | ISchool
    categoryId?: string | IDegreeCategory
    startYear?: number
    endYear?: number
    description?: string | null
    isMain: boolean
  }>
  userGroups: Array<IUserGroup>
  accessProfile: string | IProfile
  documents: Array<{
    _created: Date | string
    authorId: string | IResource
    filePath: string
    fileName: string
    fileType: string
    fileSize: number
    fileDescription: string
    isLocked: boolean
    isPublic: boolean
  }>
  comments: Array<{
    _created: Date | string
    headline: string
    message: string
    authorId: string | IResource
  }>
  index?: string
  isActivated: boolean
  CGUvalidatedDate?: Date | string
  // Automatice e-mail notification setting (array contains module Id)
  mailMod: Array<number>
}

export type ICurrentJob = {
  _id?: string
  _created?: Date | string
  isStartDate?: boolean
  positionId?: string | IJob
  position?: IJob
  startDate: Date | string
  endDate?: Date | string | null
  isAlumni?: boolean
  officeId?: string | IOffice
  office?: IOffice
  entityId?: string | ITeam | null
  entity?: ITeam
  managerId?: string | IResource | IResourceManagerID | null
  managerId2?: string | IResource | IResourceManagerID | null
  dailyCost?: number | null
  dailyPrice?: number | null
  rate?: number
  jobStatusId?: string | IJobStatus | null
  jobStatus?: IJobStatus | null
  _firmId: string | IFirm
  firm?: IFirm
  startDateLOA?: Date | string | null
  endDateLOA?: Date | string | null
  contractType?: string | IContractType
  manager?: IResource
  manager2?: IResource
  contract?: IContractType
  alumni?: {
    departureDate?: Date | string | null
    departureDatePhysical?: Date | string | null
    departureCategoryId?: string | IDepartureCategory
    departureCategory?: IDepartureCategory | null
  }
}

export type IInvitedBy = {
  role: string
  picture?: string | null
  _id: string
  firstname?: string | null
  lastname?: string | null
  userId?: string | IUser | null
}

export type IResourceJob = {
  _id: string
  _created: Date | string
  isStartDate: boolean
  positionId: string | IJob
  startDate: Date | string
  endDate?: Date | string
  isAlumni: boolean
  officeId: string | IOffice
  entityId: string | ITeam | null
  managerId: string | IResource | null
  managerId2: string | IResource | null
  dailyCost?: number
  dailyPrice?: number
  rate: number
  jobStatusId: string | IJobStatus
  _firmId?: string | IFirm
  startDateLOA: Date | string | null
  endDateLOA: Date | string | null
  contractType: string | IContractType
}

export type IResourceManagerID = {
  _id: string
  picture?: string | null
  isActive: number
  firstname?: string | null
  lastname?: string | null
  jobs: Array<ICurrentJob>
  userId?: string | IUser
  role?: string
  skills?: Array<{
    _id: string
    _created: Date | string
    skillId: string | ISkill
    isMajor: boolean
    taggedBy: Array<{
      resourceId: string | IResource
      _created: Date | string
    }>
  }>
  degrees?: Array<{
    _created: Date | string
    degree: string
    schoolId: string | ISchool
    categoryId: string | IDegreeCategory
    startYear: number | null
    endYear: number
    description?: string
    isMain: boolean
  }>
}

export type IDegreeCategory = {
  _id: string
  _created: Date | string
  display: string
  _firmId: string | IFirm
  authorId: string | IResource
  modifiedBy: Array<string | IResource>
}

export type ISchool = {
  _id: string
  _created: Date | string
  isDeleted: boolean
  display: string
  degreeType?: string
  _firmId: string | IFirm
  authorId: string | IResource
  modifiedBy: Array<IResource>
  categoryId?: string | ISchoolCategory
  countryId: string | ICountry
}

export type ILanguage = {
  _id: string
  display: string
  lang: string
}

export type IProfile = {
  _id: string
  display: string
  isDeleted: boolean
  language: string | ILanguage
  _created: Date | string
  authorId: string | ILeaveUserJobs | null
  isAdminProfile: boolean
  isSubAdminProfile: boolean
  isResourceProfile: boolean
  isCandidateProfile: boolean
  adminModules: Array<{
    _id: number
    isActive: boolean
    moduleId: number | IModule
    isAccess: boolean
    subAccess: Array<{
      _id: number
      isActive: boolean
      isRadio: boolean
      radioArray: Array<{
        _id: number
        display: { FR: string; EN: string }
      }>
      radioValue?: number
      checkboxSource?: string
      checkboxArray?: any
      display: { FR: string; EN: string }
      isAccess: boolean
    }>
  }>
  resourceModules: Array<{
    _id: number
    isActive: boolean
    moduleId: number | IModule
    isAccess: boolean
    subAccess: Array<{
      _id: number
      isActive: boolean
      isRadio: boolean
      radioArray: Array<{
        _id: number
        display: { FR: string; EN: string }
      }>
      radioValue?: number
      checkboxSource?: string | null
      checkboxArray?: any
      display: { FR: string; EN: string }
      isAccess: boolean
    }>
  }>
  _firmId: string | IFirm
}

export type IFactoryFunctionRequest<T, D> = (
  axios: IFactoryRequest<D>
) => IFactoryResponse<T, D>

export type IFactoryResponse<T, D> = {
  loading: boolean
  data?: T
  loadingError: any
  promise: Promise<T>
  refresh: (params: { query: { [key: string]: string | number | Array<string | number> }; data?: D }) => Promise<T>
}
export type IPaginated<T> = {
  docs: Array<T>
  count: {
    total: number
    currentPage: number
    nextPage: number
    totalPages: number
    hasNextPage: boolean
    limit: number
  }
}

export type IFactoryRequest<D> = {
  method: EMethod
  url: string
  data?: D
  immediate?: boolean
  query?: { [key: string]: string | number | Array<string | number> | boolean | Date }
}

export enum EMethod {
  get = 'get',
  post = 'post',
  patch = 'patch',
  delete = 'delete',
  put = 'put',
}

export type ISchoolCategory = {
  _id: string
  _created: Date | string
  display: string
  _firmId: string | IFirm
  authorId?: string | IResource
  modifiedBy: Array<IResource>
}

export type ICountry = {
  _id: string
  display: string
  flag: string
  acronym: string
  weekend?: Array<number>
}

export type ITeam = {
  _id: string
  display: string
  parentId: string | ITeam | null
  isHead: boolean
  country: string | ICountry | null
  email?: string
  _firmId: string | IFirm
}

export type ITrainingLocation = {
  _id: string
  countryId?: string | ICountry
  officeId?: string | IOffice
  _created: Date | string
  isDeleted: boolean
  authorId: IResource
  _firmId: IFirm
  display: string
  address: string
  modifiedBy: Array<string | IResource>
}
export type ITrainingCostCategory = {
  _id: string
  isDeleted: boolean
  countryId?: string | ICountry
  officeId?: string | IOffice
  _created: Date | string
  display: string
  isInternalFunding: boolean
  _firmId: string | IFirm
  authorId: IResource
  modifiedBy: Array<IResource>
}
export type ITrainingTrainer = {
  _id: string
  countryId: string | ICountry
  officeId?: string | IOffice
  _created: Date | string
  authorId: string | IResource
  _firmId: string | IFirm
  display: string
  modifiedBy: Array<string | IResource>
}

export type ITrainingCategory = {
  _id: string
  countryId?: string | ICountry
  officeId?: string | IOffice
  _created: Date | string
  display: string
  _firmId: string | IFirm
  authorId: string | IResource
  modifiedBy: Array<string | IResource>
  isDeleted: boolean
}

export type ITraining = {
  _id: string
  countryId?: string | ICountry
  officeId?: string | IOffice
  display: string
  image: {
    filePath?: string
    fileName?: string
    fileType?: string
  }
  isDeleted: boolean
  visibility: number
  isSubscribable: boolean
  isWaitable: boolean
  description: string
  presentation: string
  _created: Date | string
  _firmId: IFirm
  authorId: IResource
  targetJobs: Array<string | IJob>
  targetOffices: Array<string | IOffice>
  requiredTrainings: Array<string | ITraining>
  isActive: boolean
  duration: string // Is {hour.min) like 3.30 -> 3h & 30 min or {day}.{hours}
  isHours: boolean
  minimum: number
  maximum: number
  locations: Array<string | ITrainingLocation>
  budget: Array<{
    _id: string
    display: string
    cost: number
    quantity: number
    linkToParticipants: boolean
    currency: number
    status: number
    costCategoryId: string | ITrainingCostCategory
  }>
  externalTrainers: Array<string | ITrainingTrainer>
  internalTrainers: Array<string | IResource>
  documents: [
    {
      _id: string
      _created: Date | string
      authorId: IResource
      filePath: string
      fileName: string
      fileType: string
      fileSize: number
      fileDescription: string
      isLocked: boolean
      isPublic: boolean
    },
  ]
  skillTags: Array<string | ISkill>
  categoryId: Array<string | ITrainingCategory>
  checklist: [
    {
      _id: string
      display: string
      _created: Date | string
      completed: boolean
    },
  ]
  isInterested: [
    {
      _id: string
      resourceId: string | IResource
      sentDate: Date
    },
  ]
}

export type IMeeting = {
  _id: string
  countryId?: string | ICountry
  officeId?: string | IOffice
  isDeleted: boolean
  _created: Date | string
  isMultiple: boolean
  moduleId: number | IModule
  manualSummary: boolean
  targetObject?: string
  _firmId: string | IFirm
  sentDate: Array<Date>
  sentBy: IResource
  room: string
  method: string
  sendMail: boolean
  events: Array<{
    _id: string
    start: Date
    end: Date
    duration: number
    transp: string
    summary: string
    alarms: [number]
    uid: string
    sequence: number
    stamp: Date
    floating: boolean
    location: string
    description: string
    organizer: {
      name: string
      email: string
    }
    attendees: Array<{
      _id: string
      attendeeId: string | IResource
      name: string
      email: string
      role: string
      rsvp: boolean
      status: string
      isSent: boolean
      sentDate: Date
      doNotSend: boolean
      replyText: string
      statusChangeDate: Date
    }>
    method: string
    status: string
  }>
}

export type IFormular = {
  _id: string
  countryId: string | ICountry | null
  officeId: string | IOffice | null
  isDeleted: boolean
  _created: Date | string
  display: string
  moduleId: number | IModule
  authorId: string | IResource
  list: Array<{
    _id: string
    column: Array<{
      _id: string
      type: string
      display: string | null
      displayArray: Array<any>
      extraData: any
      value: any | null
      buttons: number
      calcId: string
      averageIds: Array<string>
      bgColor: string
      color: string
      required: boolean
      showNA: boolean
      hideable: boolean
      hiddenBy: string | IResource
      rows: number // UPDATE SCHEMA IN RECRUITMENTPROCESS, TRAININGSESSION, EVALUATIONS, SURVEYS
    }>
  }>
  _firmId: string | IFirm
}

export type ITrainingSession = {
  _id: string
  isDeleted: boolean
  countryId: string | ICountry
  officeId: string | IOffice
  _created: Date | string
  trainingId: string | ITraining
  authorId: string | IResource
  _firmId: string | IFirm
  startDate: Date | string
  endDate: Date | string
  duration: string // Is {hour.min) like 3.30 -> 3h & 30 min or {day}.{hours}
  isHours: boolean
  presentation?: string
  targetJobs: Array<string | IJob>
  targetOffices: Array<string | IOffice>
  requiredTrainings: Array<string | ITraining>
  participants: Array<{
    _id: string
    resourceId: string | IResource
    duration: number
    evaluationCompleted?: Date
    comment: string
    form: Array<{
      _id: string
      column: Array<{
        _id: string
        type: string
        display?: string
        displayArray: Array<any>
        extraData: any
        value?: any
        buttons: number
        calcId: string
        averageIds: [string]
        bgColor: string
        color: string
        required: boolean
        showNA: boolean
        hideable: boolean
        hiddenBy: string | IResource
        rows: number
      }>
    }>
    isAttended: boolean
    hasCancelled: boolean
    cancellationDate: Date
    cancellationCost: number
    cancellationComment: string
  }>
  modifiedBy: Array<string | IResource>
  formularId?: string | IFormular
  averageIds: [string]
  averageTotalIds: [string]
  isTrainerExternal: boolean
  internalTrainer: Array<string | IResource>
  externalTrainer: string | ITrainingTrainer
  location: string | ITrainingLocation
  meetings: Array<string | IMeeting>
  budget: [
    {
      _id: string
      display: string
      cost: number
      quantity: number
      linkToParticipants: boolean
      currency: number
      costCategoryId: string | ITrainingCostCategory
      status: number
      document: {
        filePath: string
        fileName: string
        fileType: string
      }
    },
  ]
  staffingPeriod: [
    {
      days: number
      startDate: Date | string
      endDate: Date | string
    },
  ]
  checklist: [
    {
      _id: string
      display: string
      _created: Date | string
      completed: boolean
      deletedDate: string | Date
    },
  ]
  comments: [
    {
      _id: string
      _created: Date | string
      headline: string
      message: string
      authorId: string | IResource
    },
  ]
  statusId: string | ITrainingStatus
}

export type ITrainingStatus = {
  _id: string
  countryId: string | ICountry
  officeId: string | IOffice
  _created: Date | string
  order: number
  display: string
  _firmId: string | IFirm
  authorId: string | IResource
  modifiedBy: [string | IResource]
}

export type ILeave = {
  _id: string
  countryId: string | ICountry | null
  officeId: string | IOffice | null
  _firmId: string | IFirm
  _created: Date | string
  isDeleted: boolean
  resourceId: string | IResource
  leavePeriod: Array<{
    startDate: Date | string
    endDate: Date | string
    day: number
    month: number
    year: number
    isAM: boolean | null
  }>
  staffingPeriod: Array<{
    days: number
    startDate: Date | string
    endDate: Date | string
    isAM: boolean | null
  }>
  type: string | ILeaveType
  validation: Array<{
    validatedBy: string | IResource
    transferedTo: string | IResource
    validationDate: Date | string
    isOverwritten: boolean
    isModification: boolean
    overWrittenBy: string | IResource
    comment: string | null
    _created: Date | string
  }>
  documents: Array<{
    _created: Date | string
    authorId: string | IResource
    filePath: string
    fileName: string
    fileType: string
    fileSize: number
    fileDescription: string
  }>
  comments: Array<{
    _created: Date | string
    headline: string
    message: string
    tagged: Array<string | IResource>
    authorId: string | IResource
  }>
  status: number // 0 waiting, 1 validated, 2 rejected, 3 cancelled
  requestCancellation: Date | null
  isCancelled: {
    _created: Date | string
    resourceId: string | IResource
  }
  editionLog: Array<{
    _created: Date | string
    resourceId: string | IResource
    startDate: Date | string | null
    endDate: Date | string | null
    days: number
    status: number // 0 waiting, 1 validated, 2 rejected, 3 cancelled
  }>
  isEdited: {
    requestDate: Date | null
    // status: Number, //0 waiting, 1 validated, 2 rejected, 3 cancelled,
    staffingPeriod: Array<{
      days: number
      startDate: Date | string | null
      endDate: Date | string | null
      isAM: boolean | null
    }>
    validatedBy: string | IResource
    type: string | ILeaveType
  }
}

export type ITakenLeave = {
  _id: string
  countryId: string | ICountry | null
  officeId: string | IOffice | null
  _firmId: string | IFirm
  _created: Date | string
  isDeleted: boolean
  resourceId: string | IResourceId
  leavePeriod: Array<{
    startDate: Date | string
    endDate: Date | string
    day: number
    month: number
    year: number
    isAM: boolean | null
  }>
  staffingPeriod: Array<{
    days: number
    startDate: Date | string
    endDate: Date | string
    isAM: boolean | null
  }>
  type: string | ILeaveType
  validation: Array<{
    _id: string
    validatedBy: string | IResourceId
    validationDate: Date | string | null
    isOverwritten: boolean
    isModification: boolean
    comment: string | null
    _created: Date | string
  }>
  documents: Array<{
    _created: Date | string
    authorId: string | IResource
    filePath: string
    fileName: string
    fileType: string
    fileSize: number
    fileDescription: string
  }>
  comments: Array<{
    _created: Date | string
    headline: string
    message: string
    tagged: Array<string | IResource>
    authorId: string | IResource
  }>
  status: number // 0 waiting, 1 validated, 2 rejected, 3 cancelled
  requestCancellation: Date | string | null
  isCancelled?: {
    _created?: Date | string
    resourceId?: string | IResource
  }
  editionLog: Array<{
    _created: Date | string
    resourceId: string | IResource
    startDate: Date | string | null
    endDate: Date | string | null
    days: number
    status: number // 0 waiting, 1 validated, 2 rejected, 3 cancelled
  }>
  isEdited: {
    staffingPeriod: Array<{
      days: number
      startDate: Date | string | null
      endDate: Date | string | null
      isAM: boolean | null
    }>
    _created?: Date | string
  }
  totalDays?: number
}

export type ILeaveType = {
  _id: string
  _created: Date | string
  display: string
  _firmId: string | IFirm
  authorId: string | IResource
  order: number
  activityMultiplicator: boolean
  formula: number
  isRemote: boolean
  color: string | null
  jobs: Array<string | IJob>
  targetResources: Array<string | IResource>
  targetOffices: Array<string | IOffice>
  validatorJobs: Array<string | IJob>
  validatorResources: Array<string | IResource>
  modifiedBy: Array<string | IResource>
  initialBalances: Array<{
    _id: string
    resourceId: string | IResource
    leavePeriods: Array<{
      _id: string
      balance: number
      periodDisplay?: string
      period: number[]
      startDate: Date | string
      isManual: boolean
    }>
  }>
}
export type ILeaveTypes = {
  _id: string
  _created: Date | string
  display: string
  _firmId?: string | IFirm
  authorId: string | IAcquiredLeaveTypeJobs
  order: number
  activityMultiplicator: boolean
  formula: number | ILeaveFormula
  isRemote: boolean
  color?: string | null
  jobs: Array<string | IJobsLeave>
  jobsExcluded: Array<string | IJobsLeave>
  targetResources: Array<string | ILeaveValidatorTargetResources>
  targetContract: Array<string | IContractType>
  targetOffices: Array<string | IOffice>
  validatorJobs: Array<string | IResource>
  validatorResources: Array<string | ILeaveValidatorTargetResources>
  modifiedBy: Array<string | IAcquiredLeaveTypeJobs>
  initialBalances: Array<{
    _id: string
    resourceId: string | ILeaveResourceId
    leavePeriods: Array<{
      _id: string
      balance: number
      periodDisplay?: string
      period: number[]
      startDate: Date | string
      isManual: boolean
    }>
  }>
  daysIncluded: {
    weekend: boolean
    holidays: boolean
  }
}

export type IInitialBalance = {
  _id: string
  leavePeriods: Array<{
    balance: number
    initialBalance: number
    periodDisplay: string
    period: number[]
    startDate?: Date | string
    acquired?: number
    taken?: number
  }>
}

export type ILeavePeriods = {
  initialBalance: number
  balance: number
  acquired: number
  taken: number
  periodDisplay: string
  period: number[]
  startDate?: Date | string
}

export type IUser = {
  _id: string
  _created?: Date
  email: string
  updateCache?: boolean
  toChangePassword?: boolean
  isActivated?: boolean
  _firmId?: IFirm
  password?: string
  salt?: string
  lang?: string
  role?: string
  pwdErrors?: number
}

export type TCountry = 'ma' | 'uk' | 'fr' | 'ae'

export type ILeaveFormula = {
  _id: number
  country: string | TCountry
  display: string
  isRemote: boolean
  isDay1Start?: boolean
  isManual: boolean
  yearLimitTransfer?: number
  isAcquiredYearly?: boolean
  period: number[]
  description: string
  roundUp?: number
  formula: (
    year: number,
    month: number,
    user: Pick<IResourceRecruitmentDate, '_firmId' | 'recruitmentDate' | 'departureDate' | 'jobs'>,
    publicHolidaysList: Array<Date | string >, // | IPublicHolidays
    ms: number
  ) => number | null
}

export type IBalanceLeaves = {
  display: string
  isManual: boolean
  leaveId: string
  leavePeriods: Array<ILeavePeriods>
  acquisitionLeft: number
  balanceTotal: number
}

export type IAcquiredLeaveType = {
  month: number
  year: number
  isManual: boolean
  leaveType: Array<IPushLeaveType>
}

export type IPushLeaveType = {
  display: string
  leaveTypeId: string
  value: number
  periodDisplay: string
  period: number[]
}

export type IMonthArray = {
  _id?: number
  display: string
}

export type ITotalLeaves = {
  _id: string
  display: string
  value: number
}

export type IPublicHolidays = {
  _id: string
  country: string
  holidays: Array<{
    _id: string
    year: number
    days: string[]
    region?: string
  }>
}

export type IIsPeriodInArray = {
  display: string
  period: number[]
}

export type IInitialeFirstBalance = {
  _id: string
  display: string
  leavePeriods: Array<{
    balance: number
    initialBalance: number
    periodDisplay: string
    period: number[]
    startDate?: Date | string
  }>
  resourceId: string | IUserResourceId
  totalBalance: number
}

export type ILeaveArray = {
  displayedYear: string
  year: number
  balanceLeaves: Array<IBalanceLeaves>
  acquiredLeaves: Array<IAcquiredLeaveType>
  takenLeaves: Array<ITakenLeave>
  takenLeavesToDate: Array<ITakenLeave>
  takenLeavesEOLM: Array<ITakenLeave>
  totalAcquired: Array<ITotalLeaves>
  totalAcquiredEOLM: Array<ITotalLeaves>
  totalTaken: Array<ITotalLeaves>
  totalTakenToDate: Array<ITotalLeaves>
  totalTakenEOLM: Array<ITotalLeaves>
  balanceTotal: number | null
  balanceTotalEOY: number | null
}

export type IFiscalYear = {
  year: number
  display: number
  displayFiscal: string
}

export type ILeaveOfYear = {
  currentTakenToDate: number
  currentTakenEOLM: number
  lost: number
  endBalance: number
  initialBalance: number
  year: number
  acquisition: number
  acquisitionEOLM: number
  taken: number
  left: number
  currentTaken: number
}

export type IResourceAccountData = IResourceRecruitmentDate & {
  concatenate: string
  data: Array<IData>
  years: Array<IFiscalYear>
}

export type IResourceAccountDataReturn = IResourceRecruitmentDate & {
  concatenate: string
  data: Array<IData>
  years: Array<ILeaveOfYear>
}

export type IData = ILeaveTypes & {
  totalTakenToDate: number
  acquisitionLeft: number
  currentBalance: number
  currentBalanceToDate: number
  currentBalanceEOLM: number
  years: Array<ILeaveOfYear>
  firstBalance: number
}

export type IGetResourceLeaveDatasParams = {
  leaveTypes: Array<ILeaveTypes & {
    formula: ILeaveFormula
  } >
  firstBalance: Array<IInitialeFirstBalance>
  employee: IResource & { years: Array<IFiscalYear>; data: Array<IData> }
  resourceLeaveArray: Array<ILeaveArray>
  thisYear: number
  publicHolidaysList: Array<Date | string > // Array<IPublicHolidays>
  monthArray: Array<IMonthArray>
}

export type IBoardingProcess = {
  _id: string
  _created: Date | string
  isOnboarding: boolean
  isDeleted: boolean
  authorId: string | IResource
  countryId: Array<string | ICountry>
  display: string
  targetJobs: Array<string | IJob>
  targetOffices: Array<string | IOffice>
  steps: Array<{
    display: string
    actions: Array<{
      actionType: number
      display: string
      responsibilityType: string
      teamId: string | ITeam
      resourceId: string | IResource
      boardingMail: string | IRecruitmentMailTemplate
      signatureTemplate: string | ISignatureTemplate
    }>
  }>
  _firmId: string | IFirm
}
