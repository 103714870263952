import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import Tracker from '@openreplay/tracker'
import trackerAssist from '@openreplay/tracker-assist'
import VueDatePicker from '@vuepic/vue-datepicker'
import piniaPluginPersistedState from 'pinia-plugin-persistedstate'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'unfonts.css'
import 'v-calendar/dist/style.css'
import 'floating-vue/dist/style.css'
import './assets/css/tailwind.css'
import './assets/css/animation.css'
import './assets/css/app.css'
import './assets/css/datePicker.css'
import VueVirtualScroller from 'vue-virtual-scroller'
import FloatingVue from 'floating-vue'
import { MotionPlugin } from '@vueuse/motion'
import { createHead } from '@unhead/vue'

// import posthog from 'posthog-js'
import App from './App.vue'
import { useApplicationStore } from '@/config/store/applicationStore'
import router from '@/config/router'
import { useUserStore } from '@/config/store/userStore'
import { APP_VERSION } from '@/config/constantes'
import i18n from '@/config/translation'

const application = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)
application.use(pinia)

// eslint-disable-next-line no-new
new Promise(() => {
  if (!window?.location.host.includes('127.0.0.1') && !window?.location.host.includes('localhost')) {
    const isSandbox = window?.location.host.includes('sandbox')
    if (isSandbox) {
      // posthog.init('phc_9rrjEPplytvnmO2S9oP0ViQVwPnLuJmnFoTRzpTRI1r', { api_host: 'https://posthog.bc.direct' })
    }

    const userStore = useUserStore()
    if (userStore.user?._id) {
      const userProperties = {
        version: APP_VERSION,
        email: userStore.user.user.email,
        resourceId: userStore.user._id,
        firstname: userStore.user.firstname,
        lastname: userStore.user.lastname,
        profileId: userStore.user.accessProfile,
        profile: userStore.user.profile?.display,
      }
      if (isSandbox) {
        // posthog.identify(
        //   userStore.user.user.email, // Required. Replace 'distinct_id' with your user's unique identifier
        //   userProperties,
        // )
      }
    }
    const tracker = new Tracker({
      projectKey: 'P5ZRIuqbVGV5AMw4dYl2',
      ingestPoint: 'https://openreplay.bc.direct/ingest',
      obscureInputDates: false,
      obscureInputEmails: false,
      obscureTextNumbers: false,
      obscureInputNumbers: false,
      obscureTextEmails: false,
    })
    tracker.start()
    tracker.use(trackerAssist({
      confirmText: 'A developer wants to start a screen sharing session.',
    }))
    if (userStore.user?.userId) {
      tracker.setUserID(userStore.user.user.email)
      tracker.setMetadata('resourceId', userStore.user._id)
      tracker.setMetadata('name', `${userStore.user.lastname} ${userStore.user.firstname}`)
      tracker.setMetadata('profileId', userStore.user.accessProfile)
      tracker.setMetadata('profile', userStore.user.profile.display)
      tracker.setMetadata('firm', userStore.user.firm.company)
      tracker.setMetadata('_firmId', userStore.user._firmId)
      tracker.setMetadata('picture', userStore.user.picture)
      tracker.setMetadata('release', APP_VERSION)
    }
  }
})

const applicationStore = useApplicationStore()
applicationStore.resetErrors()
const head = createHead()

application.use(head)
application.use(router)
application.use(i18n)
application.use(FloatingVue)
application.use(MotionPlugin)
application.use(autoAnimatePlugin)
application.use(VueVirtualScroller)
application.component('VueDatePicker', VueDatePicker)

application.mount('#app')
