import type { AxiosInstance } from 'axios'
import type { IContractType, IFactoryFunctionRequest, paginate } from '@bc-library/interface'
import { EMethod } from '@bc-library/interface'
import { API_MAIN_VERSION, BASE_URL } from '@/config/constantes'
import useAxios from '@/services/api/useAxios'

const resource = 'departureCategorys'

const filter = (axios: IFactoryFunctionRequest<paginate<Array<Pick<IContractType, '_id' | 'display'>>>, undefined>) => (options = {}) => {
  return axios({
    method: EMethod.get,
    url: `${BASE_URL}/${API_MAIN_VERSION}/${resource}/v2/filter`,
    query: options,
  })
}

export default (axios: AxiosInstance) => ({
  filter: filter(useAxios(axios)),
})
