import { getWeek } from 'date-fns'
import type { IIsPeriodInArray, ILeaveFirm, IPublicHolidays } from '../../interface/src'

/**
 * Say if the argument is in an array
 * @param array
 * @param id
 * @return boolean
 */
export const isinArray = (array: Array<any>, id: string): boolean => {
  return !!array.find((item) => {
    const isObject = typeof item === 'object'
        && !Array.isArray(item)
        && item !== null
    if (isObject)
      return item._id === id

    return item === id
  })
}

export const fiscalPeriod = ({ date = new Date(), fiscalMonthStart = 0 } = { date: new Date(), fiscalMonthStart: 0 }) => {
  const periodFiscal = fiscalYearStartEnd({ date, fiscalMonthStart })

  const previousDate = structuredClone(date)
  previousDate.setFullYear(previousDate.getFullYear() - 1)
  const nextDate = structuredClone(date)
  nextDate.setFullYear(nextDate.getFullYear() + 1)
  const days = getDaysBetweenDates(periodFiscal.startDate, periodFiscal.endDate)

  return {
    year: periodFiscal.startDate.getFullYear(),
    getPreviousPeriod: () => fiscalPeriod({ date: previousDate, fiscalMonthStart }),
    getNextPeriod: () => fiscalPeriod({ date: nextDate, fiscalMonthStart }),
    display: `${periodFiscal.startDate.toLocaleString('default', { month: 'long', year: 'numeric' })}${periodFiscal.startDate.getFullYear() !== periodFiscal.endDate.getFullYear() ? ` - ${periodFiscal.endDate.toLocaleString('default', { month: 'long', year: 'numeric' })}` : ''}`,
    years: datesToYearAndMonth(days),
  }
}

export const fiscalYearWithMonth = ({ date = new Date(), fiscalMonthStart = 0 }) => {
  const yearCopy = structuredClone(date)
  let year = date.getFullYear()
  if (date.getMonth() < fiscalMonthStart)
    year -= 1

  return {
    year,
    display: `${year}${year !== yearCopy.getFullYear() ? `- ${yearCopy.getFullYear()}` : ''}`,
    months: generateFiscalYearMonthArray({ fiscalMonthStart }),
  }
}

// start and end date of fiscal year
export const fiscalYearStartEnd = ({ date = new Date(), fiscalMonthStart = 0 } = { date: new Date(), fiscalMonthStart: 0 }) => {
  let year = date.getFullYear()
  if (date.getMonth() < fiscalMonthStart)
    year -= 1

  const startDate = new Date(year, fiscalMonthStart, 1)
  const endDate = new Date(year + 1, fiscalMonthStart, 1, 23, 59, 59)
  endDate.setDate(endDate.getDate() - 1) // last day of the previous month

  return {
    startDate,
    endDate,
  }
}

export const generateFiscalYearMonthArray = ({ fiscalMonthStart = 0 } = { fiscalMonthStart: 0 }): Array<number> => {
  const fiscalYearMonthArray: Array<number> = []
  for (let i = 0; i < 12; i++) {
    let month = fiscalMonthStart + i
    if (month > 11)
      month = month - 12

    fiscalYearMonthArray.push(month)
  }
  return fiscalYearMonthArray
}

/**
 * Return the fiscal year of a firm
 * @param date
 * @param firm
 * @return number
 */
export function getFiscalYear(date: Date | string, firm: ILeaveFirm): number {
  let thisYear = new Date(date).getFullYear()
  if (new Date(date).getMonth() < firm.fiscalMonthStart || 0)
    thisYear -= 1

  return thisYear
};

/**
 * Return the number of months
 * @param date
 * @param date
 * @return number
 */
export function countMonths(date1: Date | string, date2: Date | string): number {
  const d1 = new Date(date1)
  const d2 = new Date(date2)
  d1.setDate(1)
  d1.setDate(d1.getDate() - 1)
  d2.setDate(1)
  d2.setMonth(d2.getMonth() + 1)
  let months
  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth() + 1
  months += d2.getMonth()
  return months <= 0 ? 0 : months
};

/**
 * Return if the period is contain in the array of periods
 * @param array
 * @param period
 * @return boolean
 */
export function isPeriodInArray(array: Array<IIsPeriodInArray>, period: Array<number>): boolean {
  for (let i = 0; i < array.length; i++) {
    if (array[i].period[0] == period[0] && array[i].period[1] == period[1] && array[i].period[2] == period[2])
      return true
  }
  return false
}

/**
 * Get if the date is less or equal to an other date
 * @param firstDate
 * @param secondDate
 * @returns boolean
 */
export function isDateLessOrEqualDate(firstDate: Date | string, secondDate: Date | string): boolean {
  const date1 = new Date(firstDate)
  date1.setHours(0, 0, 0, 0)
  const date2 = new Date(secondDate)
  date2.setHours(0, 0, 0, 0)

  return (date1 <= date2)
}

/**
 * Get if the date is between two other dates
 * @param date
 * @param date1
 * @param date2
 * @returns boolean
 */
export function isDateBetweenDates(date: Date | string, date1: Date | string, date2: Date | string): boolean {
  return isDateLessOrEqualDate(date1, date) && isDateLessOrEqualDate(date, date2)
}

/**
 * Get if the key is in the array
 * @param array
 * @param element
 * @param key
 * @returns boolean
 */
export function isKeyElementinArray<T, K extends keyof T>(array: T[], element: any, key: K): boolean {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] !== null) {
      if (array[i][key] === element)
        return true
    }
  }
  return false
}

export function isDateInArrayKey(array: Array<any>, thisDate: string | Date, key: string) {
  for (let i = 0; i < array.length; i++) {
    if (isDateEqual(array[i][key], thisDate))
      return true
  }
  return false
}

export function isDateEqual(firstDate: string | Date, secondDate: string | Date) {
  const date1 = new Date(firstDate)
  date1.setHours(0, 0, 0, 0)
  const date2 = new Date(secondDate)
  date2.setHours(0, 0, 0, 0)
  return (date1.getDate() == date2.getDate()
        && date1.getMonth() == date2.getMonth()
        && date1.getFullYear() == date2.getFullYear())
}

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

export const datesAreOnSameDay = (first: Date, second: Date) => first.getFullYear() === second.getFullYear()
    && first.getMonth() === second.getMonth()
    && first.getDate() === second.getDate()

export const datesAreDaysApart = (first: Date, second: Date, nbDays = 1) => Math.abs(first.getTime() - second.getTime()) <= (86400000*nbDays)

export const isWeekend = function (day: Date) {
  return day.getDay() == 0 || day.getDay() == 6
}

type TYearAndMonth = { year: number; months: { month: number; dates: Date[]; display: string }[] }[]
export const datesToYearAndMonth = (dates: Date[]) => {
  return dates.reduce((acc: TYearAndMonth, date) => {
    const year = date.getFullYear()
    const month = date.getMonth()
    const foundYear = acc.findIndex(item => item.year === year)
    if (foundYear > -1) {
      const foundMonth = acc[foundYear].months.findIndex(item => item.month === month)
      if (foundMonth > -1) {
        acc[foundYear].months[foundMonth].dates.push(date)
      }
      else {
        acc[foundYear].months.push({
          month,
          display: date.toLocaleString('default', { month: 'long', year: 'numeric' }),
          dates: [date],
        })
      }
      return acc
    }
    acc.push({
      year,
      months: [{
        month,
        display: new Date(year, month).toLocaleString('default', { month: 'long', year: 'numeric' }),
        dates: [date],
      }],
    })
    return acc
  }, [])
}

type TYearAndMonthAndWeek = { year: number; months: { month: number; weeks: { weekNumber: number; dates: Date[] }[]; display: string }[] }[]
export const datesToYearAndMonthAndWeek = (dates: Date[]) => {
  return dates.reduce((acc: TYearAndMonthAndWeek, date) => {
    const year = date.getFullYear()
    const month = date.getMonth()
    const weekNumber = getWeek(date, { weekStartsOn: 1 })
    const foundYear = acc.findIndex(item => item.year === year)
    if (foundYear > -1) {
      const foundMonth = acc[foundYear].months.findIndex(item => item.month === month)
      if (foundMonth > -1) {
        const foundWeek = acc[foundYear].months[foundMonth].weeks.findIndex(item => item.weekNumber === weekNumber)
        if (foundWeek > -1) { acc[foundYear].months[foundMonth].weeks[foundWeek].dates.push(date) }
        else {
          acc[foundYear].months[foundMonth].weeks.push({
            weekNumber,
            dates: [date],
          })
        }
      }
      else {
        acc[foundYear].months.push({
          month,
          display: date.toLocaleString('default', { month: 'long', year: 'numeric' }),
          weeks: [{
            weekNumber,
            dates: [date],
          }],
        })
      }
      return acc
    }
    acc.push({
      year,
      months: [{
        month,
        display: new Date(year, month).toLocaleString('default', { month: 'long', year: 'numeric' }),
        weeks: [{
          weekNumber,
          dates: [date],
        }],
      }],
    })
    return acc
  }, [])
}

export const datesToWeeks = (dates: Date[]) => {
  return dates.reduce((acc: { weekNumber: number; dates: Date[] }[], date) => {
    const weekNumber = getWeek(date, { weekStartsOn: 1 })
    const foundWeek = acc.findIndex(item => item.weekNumber === weekNumber)
    if (foundWeek > -1) { acc[foundWeek].dates.push(date) }
    else {
      acc.push({
        weekNumber,
        dates: [date],
      })
    }
    return acc
  }, [])
}

export const arrayOfMonthsBetweenTwoDates = (startDate: Date, endDate: Date) => {
  const dates = getDaysBetweenDates(startDate, endDate)
  return dates.reduce<{ year: number; month: number }[]>((acc, currentValue) => {
    const month = currentValue.getMonth()
    const year = currentValue.getFullYear()
    const foundMonth = acc.find(item => item.year === year && item.month === month)
    if (!foundMonth)
      acc.push({ year, month })
    return acc
  }, [])
}

export const numberOfDaysBetweenTwoDates = (date1: Date, date2: Date) => {
  return Math.abs(Math.floor(date1.getTime() / (3600 * 24 * 1000)) - Math.floor(date2.getTime() / (3600 * 24 * 1000)))
}

export const getDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const planningDays = []
  const numberOfDays = numberOfDaysBetweenTwoDates(startDate, endDate)

  for (let i = 0; i < numberOfDays; i++) { // Loop on all days in the period given
    const dateTemp = new Date(startDate)
    dateTemp.setDate(dateTemp.getDate() + i) // add i days to the starting date
    planningDays.push(dateTemp) // push new date in planningDays
  }
  return planningDays
}

export const getNumberOfWeeksBetweenTwoDates = (date1: Date, date2: Date) => {
  const numberOfDays = numberOfDaysBetweenTwoDates(date1, date2)
  return Math.ceil(numberOfDays / 7)
}

export const getNumberOfWorkingDayBetweenTwoDates = (date1: Date, date2: Date) => {
  const numberOfDays = numberOfDaysBetweenTwoDates(date1, date2)
  let numberOfWorkingDays = 0
  for (let i = 0; i < numberOfDays; i++) {
    const dateTemp = new Date(date1)
    dateTemp.setDate(dateTemp.getDate() + i)
    if (!isWeekend(dateTemp))
      numberOfWorkingDays++
  }
  return numberOfWorkingDays
}

export const getPreviousMonday = () => {
  const date = new Date()
  const day = date.getDay()
  const prevMonday = new Date()
  if (day == 0 || day == 1)
    prevMonday.setDate(date.getDate() - (day + 6))

  else
    prevMonday.setDate(date.getDate() - (day - 1))

  return prevMonday
}

export const roundNumber = (numberToRound: number, options = { decimal: 2 }) => {
  const places = 10 ** options.decimal
  const res = Math.round(numberToRound * places) / places
  return (res)
}

/**
 *
 * Thoses 5 functions get text color from background hexa color
 *
 */

export const getRGB = (c: any) => {
  return Number.parseInt(c, 16) || c
}

export const getsRGB = (c: string) => {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : ((getRGB(c) / 255 + 0.055) / 1.055) ** 2.4
}

export const getLuminance = (hexColor: string) => {
  return (
    0.2126 * getsRGB(hexColor.slice(1, 2))
        + 0.7152 * getsRGB(hexColor.slice(3, 2))
        + 0.0722 * getsRGB(hexColor.slice(-2))
  )
}

export const getContrast = (f: string, b: string) => {
  const L1 = getLuminance(f)
  const L2 = getLuminance(b)
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
}

export const getTextColor = (bgColor: string) => {
  const whiteContrast = getContrast(bgColor, '#ffffff')
  const blackContrast = getContrast(bgColor, '#000000')

  return whiteContrast > blackContrast ? '#ffffff' : '#000000'
}

// positive magniture lighten the hex color and negative darken the color
export const newShade = (hexColor: string, magnitude: number) => {
  hexColor = hexColor.replace('#', '')
  if (hexColor.length === 6) {
    const decimalColor = Number.parseInt(hexColor, 16)
    let r = (decimalColor >> 16) + magnitude
    r > 255 && (r = 255)
    r < 0 && (r = 0)
    let g = (decimalColor & 0x0000FF) + magnitude
    g > 255 && (g = 255)
    g < 0 && (g = 0)
    let b = ((decimalColor >> 8) & 0x00FF) + magnitude
    b > 255 && (b = 255)
    b < 0 && (b = 0)
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`
  }
  else {
    return hexColor
  }
}

export const newDateToUTC = (stringDate: string | Date, tzDate: string | Date = stringDate) => {
  const thisDate = new Date(stringDate)
  thisDate.setMinutes(thisDate.getMinutes() - (tzDate ? new Date(tzDate).getTimezoneOffset() : new Date().getTimezoneOffset()))
  return thisDate
}

export const newDateFromUTC = (stringDate: string | Date, tzDate: string | Date = stringDate) => { // Transfor UTC to local date & time
  const thisDate = new Date(stringDate)
  thisDate.setMinutes(thisDate.getMinutes() + (tzDate ? new Date(tzDate).getTimezoneOffset() : new Date().getTimezoneOffset()))
  return thisDate
}
export const dateToUTC = (currentDate: Date, timezoneOffset = currentDate.getTimezoneOffset()) => {
  const copyDate = new Date(currentDate)
  copyDate.setMinutes(currentDate.getMinutes() - timezoneOffset)
  return copyDate
}

export const dateFromUTC = (currentDate: Date, timezoneOffset = currentDate.getTimezoneOffset()) => { // Transfor UTC to local date & time
  const copyDate = new Date(currentDate)
  copyDate.setMinutes(currentDate.getMinutes() + timezoneOffset)
  return copyDate
}

export const findFirstInArrayOfDates = (dates: { startDate: Date }[]) => {
  return dates.reduce((acc: Date | null, date) => {
    if (acc && acc < date.startDate)
      return acc
    return date.startDate
  }, null)
}

export const findLastInArrayOfDates = (dates: { startDate: Date }[]) => {
  return dates.reduce((acc: Date | null, date) => {
    if (acc && acc > date.startDate)
      return acc
    return date.startDate
  }, null)
}

export const isValidEmail = (email: string) => {
  return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(email)
}

export const workingDaysBetweenDates = (start:Date, end:Date) => {
  var startDate = new Date(start);
  var endDate = new Date(end);
  startDate.setHours(0,0,0,0);
  endDate.setHours(0,0,0,0);
  endDate.setDate(endDate.getDate()+1);
  var days = 0;
  var thisDate = new Date(startDate);
  var i = 0;
  while (thisDate.getTime() < endDate.getTime() && i < 10000){
      if(thisDate.getDay()!=0 && thisDate.getDay()!=6){
          days++;
      }
      thisDate.setDate(thisDate.getDate()+1);
      i++
  }
  return days * 1;
}

export const holidaysBetweenDates = (start:Date, end:Date, holidays:Date[]) => {
  var count = 0;
  holidays.forEach(function(holiday){
      if (isDateBetweenDates(holiday, start, end) && !isWeekend(holiday)) {
          count++;
      };
  })
  return count;
}

