<script setup lang="ts">
import { ref } from 'vue'
import BModal from '@/components/common/BModal.vue'
import TextInput from '@/components/common/textInput.vue';
import BButton from '../common/bButton.vue';
import { userApi } from '@/services/api'
import { useUserStore } from '@/config/store/userStore'
import { handlePromiseStatus } from '@/services/application'

const isOpen = defineModel<boolean>('isOpen')
const changingPasswordStatus = ref(null)
const userStore = useUserStore()


const oldPasswordError = ref('')
const oldPassword = ref('')
const newPassword = ref('')
const newPasswordError = ref('')
const confirmNewPassword = ref('')

const handleSubmit = () => {
  if (!newPassword.value) return;

  if (!userStore.user?.user?.toChangePassword && !oldPassword.value) {
    oldPasswordError.value = 'Please enter your current password'
    return;
  }

  oldPasswordError.value = ''

  if (!userStore.user?.user?.toChangePassword && confirmNewPassword.value !== newPassword.value) {
    newPasswordError.value = 'Password confirmation does not match'
    return;
  }

  newPasswordError.value = ''

  const changePasswordRequest = userApi.changeUserPassword(userStore.user.user._id, { oldPassword: oldPassword.value, newPassword: newPassword.value })
  handlePromiseStatus(changePasswordRequest.promise, { loadingStatus: changingPasswordStatus })

  changePasswordRequest.promise.then(() => {
    userStore.user.user.toChangePassword = false
    isOpen.value = false
    oldPassword.value = ''
    newPassword.value = ''
    confirmNewPassword.value = ''
  }).catch((error) => {
    console.error(error)
    if (error.response.data.customMessage) {
      // Not in old because if toChangePassword oldpassword is not showed
      newPasswordError.value = error.response.data.customMessage
      return;
    }
  })
};

</script>

<template>
  <BModal v-model="isOpen" position="left" :disable-back-drop="userStore?.user?.user?.toChangePassword"
    :disable-close="userStore?.user?.user?.toChangePassword">
    <template #header>
      <h2>Change password</h2>
    </template>
    <template #default>
      <form @submit.prevent="handleSubmit" class="flex flex-col gap-5 max-w-96">

        <TextInput v-if="!userStore.user?.user?.toChangePassword" label="Current password" input-type="password" id="currentPassword" v-model="oldPassword"
          name="currentPassword" required autocomplete="current-password" placeholder="Your current Password"
          aria-label="Current password" :error-message="oldPasswordError" />

        <div class="flex flex-col gap-2">
          <TextInput input-type="password" id="newPassword" v-model="newPassword" required autocomplete="new-password"
            placeholder="Your new password" aria-label="Your new password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).{8,}"
            :error-message="newPasswordError" />
          <p class="text-xs subtitle italic">
            The password must be at least 8 characters, contain at least one uppercase letter, one lowercase
            letter, one number and one special character <br> (ex: <span class="px-2 py-1 text-white bg-fade rounded">!@#$%^&*()-_+=<>?</span>).</p>
        </div>


        <TextInput input-type="password" id="confirmNewPassword" v-model="confirmNewPassword" name="confirmNewPassword"
          required autocomplete="new-password" placeholder="Confirm your new password"
          aria-label="Confirm your new password" />

        <div class="flex items-center justify-end">
          <BButton type="submit" :loading-status="changingPasswordStatus">
            Change password
          </BButton>
        </div>
      </form>
    </template>
  </BModal>
</template>
