import type { RouteRecordRaw } from 'vue-router'
import { guardEnterProject, guardPlanningResource, guardProjectAccess, guardProjectCreation } from '@/config/router/navigationsGuards/guardProject'

const MODULE_NAME = 'EXPENSE'
const MODULE_ID = 654

const projectRoutes: Array<RouteRecordRaw> = [
  {
    path: '/expense',
    meta: {
      requiresAuth: true,
    },
    beforeEnter: [guardProjectAccess],
    children: [
      {
        path: '',
        name: 'Expenses',
        component: () => import('@/views/expense/Home.vue'),
        meta: {
          requiresAuth: true,
          titleTranslationKey: 'pageTitle.projects',
        },
        beforeEnter: [],
      },

    ],

  },
]

export default projectRoutes.map(route => ({ ...route, meta: { ...route.meta, moduleName: MODULE_NAME, moduleId: MODULE_ID } }))
